/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetSettingsPopulateResponse = {
    status: GetSettingsPopulateResponse.status;
    body: {
        user_list: Array<{
            user_id: string;
            first_name: string;
            last_name: string;
            user_email: string;
            user_type: string;
            assigned_units: Array<{
                /**
                 * ID of the assigned business unit
                 */
                assigned_unit_id: string;
                /**
                 * Name of the assigned business unit
                 */
                'Assigned Business Units': string;
            }>;
            user_title_role?: string;
            is_active?: boolean;
        }>;
        org_structure: {
            organizations: {
                organization_name: string;
                organization_id: string;
                divisions: Record<string, any>;
            };
            Facilities: Record<string, any>;
        };
        utility_accounts: Array<{
            account_id: string;
            account_number: string;
            utility_id: string;
            utility_name: string;
            commodity: string;
            division_id: string;
            division_name: string;
            group_id: string;
            group_name: string;
            facility_id: string;
            facility_name: string;
            data_source: string;
        }>;
        login_credentials: Array<{
            request_id?: string;
            facility_id: string;
            facility_name: string;
            utility_name: string;
            utility_account_id: string;
            account_number: string;
            user_name: string;
            password: string;
            requested_by_user_id: string;
            request_time_stamp: string;
            last_update_timestamp: string;
        }>;
        user_platform_details: {
            currency: string;
            metric_or_imperial: GetSettingsPopulateResponse.metric_or_imperial;
            time_zone: string;
        };
    };
    message: string;
};

export namespace GetSettingsPopulateResponse {
    export enum status {
        SUCCESS = 'Success',
    }

    export enum metric_or_imperial {
        M = 'M',
        I = 'I',
    }
}
